import {
  ArrowForward,
  CalendarToday,
  Check,
  Close,
  KeyboardArrowDown,
  KeyboardDoubleArrowLeft,
  NavigateBefore,
  NavigateNext,
  Today,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const DateRange = (props: any) => {
  const { data, onUpdate } = props;

  const [value, setValue] = useState<any>();

  const [anchorEl, setAnchorEl] = useState<any>();
  const open = Boolean(anchorEl);
  const [menuAnchorEl, setMenuAnchorEl] = useState<any>();
  const menuOpen = Boolean(menuAnchorEl);

  useEffect(() => {
    setValue({
      from: data.from,
      to: data.to,
    });
  }, [data]);

  const label = (() => {
    if (!value) return "N/A";

    return `${dayjs(value.from).format("DD/MM HH:mm")} - ${dayjs(
      value.to
    ).format("DD/MM HH:mm")}`;
  })();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = (newValue: any) => {
    onUpdate?.(newValue);
    setAnchorEl(null);
  };

  const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!menuOpen) setMenuAnchorEl(event.currentTarget);
    else setMenuAnchorEl(null);
  };
  const menuHandleClose = () => {
    setMenuAnchorEl(null);
  };

  const getValue = (text: string) => {
    switch (text) {
      case "Today":
        return {
          from: dayjs().startOf("date").toDate(),
          to: dayjs().endOf("date").toDate(),
        };

      case "Yesterday":
        return {
          from: dayjs().startOf("date").add(-1, "day").toDate(),
          to: dayjs().endOf("date").add(-1, "day").toDate(),
        };

      case "Tomorrow":
        return {
          from: dayjs().startOf("date").add(1, "day").toDate(),
          to: dayjs().endOf("date").add(1, "day").toDate(),
        };

      case "This week":
        return {
          from: dayjs().startOf("week").toDate(),
          to: dayjs().endOf("week").toDate(),
        };

      case "Last week":
        return {
          from: dayjs().startOf("week").add(-1, "week").toDate(),
          to: dayjs().endOf("week").add(-1, "week").toDate(),
        };

      case "Next week":
        return {
          from: dayjs().startOf("week").add(1, "week").toDate(),
          to: dayjs().endOf("week").add(1, "week").toDate(),
        };

      case "This month":
        return {
          from: dayjs().startOf("month").toDate(),
          to: dayjs().endOf("month").toDate(),
        };

      case "Last month":
        return {
          from: dayjs().startOf("month").add(-1, "month").toDate(),
          to: dayjs().endOf("month").add(-1, "month").toDate(),
        };

      case "Next month":
        return {
          from: dayjs().startOf("month").add(1, "month").toDate(),
          to: dayjs().endOf("month").add(1, "month").toDate(),
        };
    }
  };

  const handleClickMenuItem = (e: any) => {
    menuHandleClose();
    const newValue = getValue(e.target.innerText);
    onUpdate?.(newValue);
  };

  return (
    <>
      <IconButton onClick={menuHandleClick} size="small">
        <Today />
      </IconButton>
      <Button onClick={handleClick}>{label}</Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container m={1} spacing={1} sx={{ width: "210px" }}>
          <Grid item xs={12}>
            <DateTimePicker
              label="From"
              format="DD/MM/YYYY HH:mm"
              slotProps={{ textField: { size: "small" } }}
              value={dayjs(value?.from)}
              onChange={(v) => setValue({ ...value, from: dayjs(v).toDate() })}
              ampm={false}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label="To"
              slotProps={{ textField: { size: "small" } }}
              format="DD/MM/YYYY HH:mm"
              value={dayjs(value?.to)}
              onChange={(v) => setValue({ ...value, to: dayjs(v).toDate() })}
              ampm={false}
            />
          </Grid>
          <Grid
            item
            xs={12}
            //alignItems="center"
            display="flex"
            //justifyContent="right"
          >
            <div style={{ flex: "1 0 0" }}></div>
            <Tooltip title="Apply">
              <IconButton size="small" onClick={() => handleApply(value)}>
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton size="small" onClick={handleClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Popover>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={menuHandleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClickMenuItem}>Today</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Yesterday</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Tomorrow</MenuItem>
        <Divider />
        <MenuItem onClick={handleClickMenuItem}>This week</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Last week</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Next week</MenuItem>
        <Divider />
        <MenuItem onClick={handleClickMenuItem}>This month</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Last month</MenuItem>
        <MenuItem onClick={handleClickMenuItem}>Next month</MenuItem>
      </Menu>
    </>
  );
};

export default DateRange;
