import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Refresh } from "@mui/icons-material";
import Heading from "../../shared/Heading";
import useUsers from "./hooks/useUsers";
import MUIDataTable from "mui-datatables";
import { UserProfileContext } from "../../context/UserProfileContext";

const AdministratorUsers = () => {
  const navigate = useNavigate();
  const profile: any = useContext(UserProfileContext);
  const [data] = useState({
    data: { userId: profile.id },
  });
  const [users, refresh] = useUsers(data);

  const columns = [
    { name: "id", label: "Id" },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    {
      name: "isAdministrator",
      label: "Administrator",
      options: {
        customBodyRender: (val: any) => <Checkbox checked={val} />,
      },
    },
    {
      name: "isManager",
      label: "Manager",
      options: {
        customBodyRender: (val: any) => <Checkbox checked={val} />,
      },
    },
  ];

  const onRowClick = (row: any, { dataIndex }: any) => {
    const id = users[dataIndex].id;
    navigate(`/administrator/user/${id}`);
  };

  return (
    <>
      <MUIDataTable
        title={
          <Stack direction="row" spacing={1} alignItems="start">
            <Heading text="Users" />
            <Tooltip title="Refresh">
              <IconButton onClick={() => refresh()} size="small">
                <Refresh />
              </IconButton>
            </Tooltip>
          </Stack>
        }
        data={users}
        columns={columns}
        options={{ selectableRowsHideCheckboxes: true, onRowClick }}
      />
    </>
  );
};

export default AdministratorUsers;
