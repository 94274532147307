import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Heading from "../../shared/Heading";
import useBookings from "./hooks/useBookings";
import { useContext, useEffect, useState } from "react";
import { Flight, Refresh } from "@mui/icons-material";
import Status from "../../shared/Status";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import DateRangePicker from "../../shared/DateTimeRangePicker";
import { UserProfileContext } from "../../context/UserProfileContext";
import { SettingsContext } from "../../context/SettingsContext";

const ManagerBookings = () => {
  const navigate = useNavigate();
  const profile: any = useContext(UserProfileContext);
  const [settings, setSettings] = useContext<any>(SettingsContext);
  const [filter, setFilter] = useState<any>();
  const [bookings, refresh] = useBookings({
    accountId: profile.accountId,
    data: filter,
    isDefered: !filter,
  });

  useEffect(() => {
    setFilter({
      userId: profile.id,
      from: settings.from,
      to: settings.to,
    });
  }, [settings]);

  const columns = [
    { name: "id", label: "Id" },
    {
      name: "customer",
      label: "Customer",
      options: {
        customBodyRender: (customer: any) => {
          return <div>{customer?.name}</div>;
        },
      },
    },
    {
      name: "id",
      label: "Passenger",
      options: {
        customBodyRender: (id: any) => {
          const row = bookings.find((a: any) => a.id === id);
          if (!row) return <></>;
          return (
            <>
              <Typography variant="button">{row.passengerName}</Typography>
              <div>{row.passengerPhone}</div>
            </>
          );
        },
      },
    },
    {
      name: "id",
      label: "Pickup",
      options: {
        customBodyRender: (id: any) => {
          const row = bookings.find((a: any) => a.id === id);
          if (!row) return <></>;
          return (
            <>
              <Typography variant="button">{row.fromAddress}</Typography>
              {row.fromFlightNo && (
                <div>
                  <Flight sx={{ transform: "rotate(180deg)" }} />{" "}
                  <Typography sx={{ display: "inline-block" }} variant="h6">
                    {row.fromFlightNo}
                  </Typography>
                </div>
              )}
              <div>{dayjs(row.fromTime).format("DD/MM HH:mm")}</div>
            </>
          );
        },
      },
    },
    {
      name: "id",
      label: "Dropoff",
      options: {
        customBodyRender: (id: any) => {
          const row = bookings.find((a: any) => a.id === id);
          if (!row) return <></>;
          return (
            <>
              <Typography variant="button">{row.toAddress}</Typography>
              {row.toFlightNo && (
                <div>
                  <Flight />{" "}
                  <Typography sx={{ display: "inline-block" }} variant="h6">
                    {row.toFlightNo}
                  </Typography>
                </div>
              )}
              <Typography variant="body2">
                {dayjs(row.toTime).format("DD/MM HH:mm")}
              </Typography>
            </>
          );
        },
      },
    },
    { name: "notes", label: "Notes" },
    {
      name: "driver",
      label: "Driver",
      options: {
        customBodyRender: (driver: any) => {
          return driver?.name;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (val: any) => <Status label={val} />,
      },
    },
  ];

  const onRowClick = (row: any, { dataIndex }: any) => {
    const id = bookings[dataIndex].id;
    navigate(`/${profile.accountId}/manager/booking/${id}`);
  };

  const onUpdate = async (data: any) => {
    const newSettings = { ...settings, from: data.from, to: data.to };
    setSettings(newSettings);
  };

  return (
    <>
      <MUIDataTable
        title={
          <Stack direction="row" alignItems="start" spacing={1}>
            <Heading text="Manager Bookings" />

            <DateRangePicker
              data={{ from: settings.from, to: settings.to }}
              onUpdate={onUpdate}
            />
          </Stack>
        }
        data={bookings}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: true,
          onRowClick,
          pagination: false,
          filter: false,
          viewColumns: false,
          print: false,
          download: false,
          tableBodyMaxHeight: `calc(100vh - ${settings.headerHeight})`,
          customToolbar: () => (
            <>
              <Tooltip title="Refresh">
                <IconButton onClick={() => refresh()} size="small">
                  <Refresh />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
      />
    </>
  );
};

export default ManagerBookings;
