import usePostItems from "../../../shared/usePostItems";

const useUsers = (props: any) => {
  const { data } = props;
  return usePostItems({
    url: `users/list`,
    data,
  });
};

export default useUsers;
