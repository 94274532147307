import {
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import useAccount from "./hooks/useAccount";
import { Add, Save } from "@mui/icons-material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import useUpdateAccount from "./hooks/useUpdateAccount";
import { useSnackbar } from "notistack";
import { UserProfileContext } from "../../context/UserProfileContext";
import MyTextField from "../forms/MyTextField";
import { Formik } from "formik";

const AdministratorAccount = () => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [item, refresh] = useAccount({
    isDefered: id === undefined,
    id,
  });
  const { update, del, get } = useUpdateAccount();
  const profile: any = useContext(UserProfileContext);

  const onUpdate = async (booking: any) => {
    const response: any = await update(booking);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id) navigate(`/administrator/account/${response.data.id}`);
    }
  };

  const onDelete = async () => {
    const response = await del(id);
    if (response) {
      enqueueSnackbar("Deleted", { variant: "success" });
      navigate(`/administrator/account`);
    }
  };

  return (
    <Formik
      initialValues={item ?? {}}
      enableReinitialize={true}
      onSubmit={(values) => {
        onUpdate(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper elevation={4} sx={{ p: 1 }}>
            <Stack direction="row" p={2} spacing={1}>
              <Heading text={id ? `Account #${id}` : "New Account"} />
              <Stack direction="row">
                <Tooltip title="Save">
                  <IconButton size="small" type="submit">
                    <Save />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>

            <Grid container spacing={1}>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="name"
                  label="Name"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="address"
                  label="Address"
                  multiline
                  fullWidth
                  rows={5}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="email"
                  label="Email"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="contact"
                  label="Contact"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="website"
                  label="Website"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="phone"
                  label="Phone"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="latitude"
                  label="Latitude"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="longitude"
                  label="Longitude"
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default AdministratorAccount;
