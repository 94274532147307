import usePostItems from "../../../shared/usePostItems";

const useUsers = (props: any) => {
  const { accountId, data } = props;
  return usePostItems({
    url: `managers/${accountId}/users/list`,
    data,
  });
};

export default useUsers;
