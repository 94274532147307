import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./ErrorPage";
import ClientBooking from "./components/booking/ClientBooking";
import UserSettings from "./components/member/UserSettings";
import ClientBookings from "./components/booking/ClientBookings";
import Jobs from "./components/driver/Jobs";
import Job from "./components/driver/Job";
import ManagerBookings from "./components/manager/ManagerBookings";
import ManagerBooking from "./components/manager/ManagerBooking";
import UserDashboard from "./components/member/UserDashboard";
import AdministratorUsers from "./components/administrator/AdministratorUsers";
import AdministratorAccounts from "./components/administrator/AdministratorAccounts";
import AdministratorAccount from "./components/administrator/AdministratorAccount";
import ManagerUsers from "./components/manager/ManagerUsers";
import ManagerUser from "./components/manager/ManagerUser";
import AdministratorUser from "./components/administrator/AdministratorUser";
import Customer from "./components/customer/Customer";
import Customers from "./components/customer/Customers";

export const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <UserDashboard />,
      },
      { path: "user-settings", element: <UserSettings /> },
      {
        path: "administrator",
        children: [
          { path: "accounts", element: <AdministratorAccounts /> },
          { path: "account/:id?", element: <AdministratorAccount /> },
          { path: "users", element: <AdministratorUsers /> },
          { path: "user/:id", element: <AdministratorUser /> },
        ],
      },
      {
        path: "/:accountId",
        children: [
          { path: "", element: <Navigate to="bookings" replace /> },
          { path: "bookings", element: <ClientBookings /> },
          { path: "booking/:id?", element: <ClientBooking /> },
          {
            path: "driver",
            children: [
              { path: "jobs", element: <Jobs /> },
              { path: "job/:id?", element: <Job /> },
            ],
          },
          {
            path: "manager",
            children: [
              { path: "bookings", element: <ManagerBookings /> },
              { path: "booking/:id?", element: <ManagerBooking /> },
              { path: "users", element: <ManagerUsers /> },
              { path: "user/:id", element: <ManagerUser /> },
              { path: "customers", element: <Customers /> },
              { path: "customer/:id?", element: <Customer /> },
            ],
          },
        ],
      },
    ],
  },
]);
