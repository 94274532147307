import { useContext } from "react";
import { UserProfileContext } from "../../context/UserProfileContext";
import useUserDashboard from "./hooks/useUserDashboard";
import Heading from "../../shared/Heading";
import AccountDashboard from "./AccountDashboard";
import { Stack } from "@mui/material";

const UserDashboard = () => {
  const userProfile: any = useContext(UserProfileContext);
  const [dashboard] = useUserDashboard();

  return (
    <>
      <Stack direction="row" flexWrap="wrap">
        {dashboard &&
          dashboard.accounts.map((account: any) => (
            <AccountDashboard key={account.id} item={account} />
          ))}
      </Stack>
    </>
  );
};

export default UserDashboard;
