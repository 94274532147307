import { useEffect, useState } from "react";
import useApi from "../useApi";

const useItem = (props: any) => {
  const { url, id, isDefered } = props;
  const { getApi } = useApi();
  const [item, setItem] = useState<any>();

  const refresh = async (id: any) => {
    let url2 = url;
    if (id) url2 = `${url}/${id}`;

    const response = await getApi().get(url2);
    if (response) setItem(response.data);
    return response;
  };

  useEffect(() => {
    if (isDefered) return;

    refresh(id);
  }, [url, id, isDefered]);

  return [item, refresh];
};

export default useItem;
