import { useSnackbar } from "notistack";
import useApi from "../../../useApi";

const useImport = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = props;
  const { getApi } = useApi();
  const importFn = async () => {
    const response = await getApi().post(`/${accountId}/customers/import`);
    console.log(response);
    enqueueSnackbar("Customers imported", { variant: "success" });
  };
  return { importFn };
};
export default useImport;
