import { Flight } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";

const MyFlightNo = (props: any) => {
  const { name, label, rules, arrival } = props;
  const { values, handleChange } = useFormikContext<any>();
  const { enqueueSnackbar } = useSnackbar();

  const onClick = () => {
    const flightNo = values[name];
    if (!flightNo) {
      enqueueSnackbar(`Missing FlightNo`, {
        variant: "error",
      });
      return;
    }

    const match = /^([A-Z]+)(\d+)/g.exec(flightNo);

    if (!match) {
      enqueueSnackbar(`Could not find FlightNo in ${flightNo}`, {
        variant: "error",
      });
      return;
    }

    window.open(
      `https://www.flightstats.com/v2/flight-tracker/${match[1]}/${match[2]}`,
      "_blank"
    );
  };

  return (
    <TextField
      name={name}
      label={label}
      InputLabelProps={{ shrink: true }}
      value={values[name] ?? ""}
      size="small"
      sx={{ width: 246 }}
      variant="outlined"
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} edge="end">
              {!arrival && <Flight />}
              {arrival && <Flight sx={{ transform: "rotate(180deg)" }} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MyFlightNo;
