import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormikContext } from "formik";

const MySelect = (props: any) => {
  const { name, label, rules, options } = props;

  const { values, handleChange } = useFormikContext<any>();
  const value = values[name];

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        variant="outlined"
        value={value ?? ""}
        size="small"
        onChange={handleChange}
      >
        <MenuItem key="" value={""}></MenuItem>
        {options.map((driver: any) => {
          return (
            <MenuItem key={`${driver.id}`} value={driver.id}>
              {driver.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MySelect;
