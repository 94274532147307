import { Business, Person } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdministratorMenu = (props: any) => {
  const { onClose } = props;
  const navigate = useNavigate();

  const menu = [
    {
      icon: <Business />,
      text: "Accounts",
      route: `administrator/accounts`,
    },
    {
      icon: <Person />,
      text: "Users",
      route: `administrator/users`,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Adminstrator
        </Typography>
        <Divider />
        <List>
          {menu.map((item: any, index: number) => (
            <>
              <ListItem
                key={`${item.text}-${index}`}
                disablePadding
                onClick={() => {
                  onClose();
                  navigate(item.route);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default AdministratorMenu;
