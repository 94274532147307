import { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, FileDownload, FileUpload, Refresh } from "@mui/icons-material";
import Heading from "../../shared/Heading";
import MUIDataTable from "mui-datatables";
import useCustomers from "./hooks/useCustomers";
import useImport from "./hooks/useImport";
import { UserProfileContext } from "../../context/UserProfileContext";
import { SettingsContext } from "../../context/SettingsContext";

const Customers = () => {
  const navigate = useNavigate();

  const [settings] = useContext(SettingsContext);
  const profile: any = useContext(UserProfileContext);
  const { importFn } = useImport({ accountId: profile.accountId });
  const [filter, setFilter] = useState<any>({});
  const [customers, refresh] = useCustomers({
    accountId: profile.accountId,
    data: filter,
  });

  const columns = [
    { name: "id", label: "Id" },
    { name: "name", label: "Name" },
    { name: "refNo", label: "Ref No" },
  ];

  const onRowClick = (row: any, { dataIndex }: any) => {
    const id = customers[dataIndex].id;
    navigate(`/${profile.accountId}/manager/customer/${id}`);
  };

  const onAdd = () => {
    navigate(`/${profile.accountId}/manager/customer`);
  };

  return (
    <>
      <MUIDataTable
        title={
          <>
            <Stack direction="row" alignItems="start" spacing={1}>
              <Heading text="Customers" />
            </Stack>
          </>
        }
        data={customers}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: true,
          onRowClick,
          pagination: false,
          filter: false,
          viewColumns: false,
          print: false,
          download: false,
          tableBodyMaxHeight: `calc(100vh - ${settings.headerHeight})`,
          customToolbar: () => (
            <>
              <Tooltip title="Refresh">
                <IconButton onClick={() => refresh()} size="small">
                  <Refresh />
                </IconButton>
              </Tooltip>
              <Tooltip title="Import">
                <IconButton onClick={() => importFn()} size="small">
                  <FileUpload />
                </IconButton>
              </Tooltip>
              <Tooltip title="New Customer">
                <IconButton onClick={() => onAdd()} size="small">
                  <Add />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
      />
    </>
  );
};

export default Customers;
