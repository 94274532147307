import { TextField } from "@mui/material";
import { useFormikContext } from "formik";

const MyTextField = (props: any) => {
  const { name, label, rules, readOnly = false, rows } = props;
  const formik = useFormikContext<any>();
  return (
    <TextField
      name={name}
      label={label}
      InputLabelProps={{ shrink: true }}
      // inputProps={{ readOnly: readOnly }}
      fullWidth
      size="small"
      multiline={rows > 1}
      rows={rows}
      variant="outlined"
      value={formik.values[name] ?? ""}
      onChange={formik.handleChange}
      error={Boolean(formik.errors[name])}
      helperText={<>{formik.errors[name]}</>}
    />
  );
};

export default MyTextField;
