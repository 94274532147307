import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ConfigContext } from "./context/ConfigContext";
import { useContext } from "react";

const MyMsalProvider = (props: any) => {
  const { children } = props;

  const config: any = useContext(ConfigContext);

  const msalConfiguration: Configuration = {
    auth: config.auth,
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      //storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  const pca = new PublicClientApplication(msalConfiguration);

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

export default MyMsalProvider;
