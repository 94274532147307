import { Hail, LocalTaxi } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserProfileContext } from "../context/UserProfileContext";

const DriverMenu = (props: any) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const profile: any = useContext(UserProfileContext);

  const menu = [
    {
      icon: <LocalTaxi />,
      text: "Jobs",
      route: `/${profile.accountId}/driver/jobs`,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Driver
        </Typography>
        <Divider />
        <List>
          {menu.map((item: any, index: number) => (
            <ListItem
              key={`${item.text}-${index}`}
              disablePadding
              onClick={() => {
                onClose();
                navigate(item.route);
              }}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default DriverMenu;
