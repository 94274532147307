import useApi from "../useApi";

const useUpdateItem = (props: any) => {
  const { url } = props;
  const { getApi } = useApi();

  const get = async (action: string) => {
    return await getApi().get(`${url}/${action}`);
  };

  const update = async (data: any) => {
    return await getApi().post(url, data);
  };

  const del = async (id: any) => {
    return await getApi().delete(`${url}/${id}`);
  };

  return { update, del, get };
};

export default useUpdateItem;
