import axios, { InternalAxiosRequestConfig } from "axios";
import { useContext } from "react";
import { ConfigContext } from "./context/ConfigContext";
import { useMsal } from "@azure/msal-react";
import { useSnackbar } from "notistack";

const useApi = () => {
  const config: any = useContext(ConfigContext);
  const { accounts, instance } = useMsal();
  const { enqueueSnackbar } = useSnackbar();

  const account = accounts?.length > 0 ? accounts[0] : undefined;

  const getToken = async () => {
    const request = {
      scopes: [config.api.scope],
      account,
    };

    return await instance.acquireTokenSilent(request);
  };

  const getApi = () => {
    const api = axios.create({
      baseURL: config.api.url,
    });

    api.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        config.headers!.Authorization = `Bearer ${token.accessToken}`;
        config.headers!["Content-Type"] = "application/json";
        return config;
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        //return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        // if (error.response.status === 401) {
        //   window.location.href = '/';
        // }

        enqueueSnackbar(error.message, { variant: "error" });
      }
    );

    return api;
  };

  return { getApi };
};

export default useApi;
