import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useFormikContext } from "formik";

const MyDateTimePicker = (props: any) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { name, label, rules } = props;
  return (
    <DateTimePicker
      label={label}
      slotProps={{ textField: { size: "small" } }}
      value={dayjs(values[name] ?? new Date())}
      onChange={(newValue) => setFieldValue(name, newValue?.toDate())}
      ampm={false}
      format="DD/MM/YYYY HH:mm"
    />
  );
};

export default MyDateTimePicker;
