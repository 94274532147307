import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useFormikContext } from "formik";
import { Controller, useFormContext } from "react-hook-form";

const MyCheckbox = (props: any) => {
  const { name, label, rules } = props;
  const { values, handleChange, setFieldValue } = useFormikContext<any>();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={values[name] ?? false}
            onChange={(e) => setFieldValue(name, e.target.checked)}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default MyCheckbox;
