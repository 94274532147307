import { useEffect, useState } from "react";
import useApi from "../useApi";

const usePostItems = (props: any) => {
  const { url, data, isDefered } = props;
  const { getApi } = useApi();
  const [items, setItems] = useState<any>([]);

  const refresh = async (newData: any = undefined) => {
    const postData = newData ?? data;
    const response = await getApi().post(url, postData);
    if (response) setItems(response.data);

    return response;
  };

  useEffect(() => {
    if (isDefered) return;

    refresh(data);
  }, [data, isDefered]);

  return [items, refresh];
};

export default usePostItems;
