import { Grid, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import useUpdateBooking from "./hooks/useUpdateBooking";
import useBooking from "./hooks/useBooking";
import { Cancel, Delete, Save } from "@mui/icons-material";
import { UserProfileContext } from "../../context/UserProfileContext";
import Status from "../../shared/Status";
import { useSnackbar } from "notistack";
import GMRouteMap from "../../shared/GMRouteMap";
import MyAddress from "../forms/MyAddress";
import MyTextField from "../forms/MyTextField";
import MyDateTimePicker from "../forms/MyDateTimePicker";
import MyFlightNo from "../forms/MyFlightNo";
import MyVia from "../forms/MyVia";
import MyCustomerAutocomplete from "../forms/MyCustomerAutocomplete";
import { Formik } from "formik";
import * as yup from "yup";

const BookingSchema = yup.object().shape({
  customerId: yup.number().required("Required"),
  passengerName: yup.string().required("Required"),
  passengerPhone: yup.string().required("Required"),
  fromAddress: yup.string().required("Required"),
  toAddress: yup.string().required("Required"),
  fromTime: yup.string().required("Required"),
  toTime: yup.string().required("Required"),
});

const ClientBooking = () => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [item, setItem] = useState<any>({});
  const [booking, refresh] = useBooking({
    isDefered: id === undefined,
    id,
  });
  const { update, del, get } = useUpdateBooking();
  const profile: any = useContext(UserProfileContext);
  const [route, setRoute] = useState<any>();

  useEffect(() => {
    if (!id) {
      setItem({
        fromTime: new Date(),
        toTime: new Date(),
        via: [],
      });
      setRoute({});
    }
  }, [id]);

  useEffect(() => {
    if (!booking) return;
    setItem(booking);
    setRoute(booking);
  }, [booking]);

  const onUpdate = async (data: any) => {
    const newItem = { ...item, ...data, accountId: profile.accountId };

    const response: any = await update(newItem);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id) navigate(`/${profile.accountId}/booking/${response.data.id}`);
    }
  };

  const onUpdateStatus = async (status: string) => {
    const response = await get(`${id}/status/${status}`);
    if (response) {
      enqueueSnackbar("Status updated", { variant: "success" });
      await refresh(id);
    }
  };

  const onDelete = async () => {
    const response = await del(id);
    if (response) {
      enqueueSnackbar("Deleted", { variant: "success" });
      navigate(`/${profile.accountId}/bookings`);
    }
  };

  return (
    <>
      <Formik
        initialValues={item}
        enableReinitialize={true}
        validationSchema={BookingSchema}
        onSubmit={(values) => {
          onUpdate(values);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Paper elevation={4} sx={{ p: 1, mb: 2 }}>
              <Stack direction="row" p={1} spacing={1}>
                <Heading text={id ? `Booking #${id}` : "New booking"} />
                {item && <Status label={item.status} />}
                <Tooltip title="Save">
                  <IconButton size="small" type="submit">
                    <Save />
                  </IconButton>
                </Tooltip>
                {id && (
                  <>
                    <Tooltip title="Cancel">
                      <IconButton
                        size="small"
                        onClick={() => onUpdateStatus("Cancelled")}
                      >
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={() => onDelete()}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MyCustomerAutocomplete name="customerId" label="Customer" />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    name="passengerName"
                    label="Passenger Name"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField name="passengerPhone" label="Passenger Phone" />
                </Grid>

                <Grid item xs={12}>
                  <MyAddress
                    name="fromAddress"
                    label="Pickup From"
                    onUpdate={(e: any) => {
                      setRoute({ ...route, fromAddress: e });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyFlightNo
                    arrival
                    name="fromFlightNo"
                    label="Arrival Flight No"
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyDateTimePicker name="fromTime" label="Pickup Time" />
                </Grid>

                <Grid item xs={12}>
                  <MyVia
                    name="via"
                    label="Via"
                    onUpdate={(e: any) => setRoute({ ...route, via: e })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyAddress
                    name="toAddress"
                    label="Dropoff To"
                    onUpdate={(e: any) => {
                      setRoute({ ...route, toAddress: e });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyFlightNo name="toFlightNo" label="Departure Flight No" />
                </Grid>

                <Grid item xs={12}>
                  <MyDateTimePicker name="toTime" label="Dropoff Time" />
                </Grid>

                <Grid item xs={12}>
                  <MyTextField name="notes" label="Notes" rows={5} />
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      </Formik>

      <GMRouteMap
        center={{ latitude: profile.latitude, longitude: profile.longitude }}
        route={route}
        showResult
      />
    </>
  );
};

export default ClientBooking;
