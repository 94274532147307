import dayjs from "dayjs";
import { createContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const SettingsContext = createContext<any>({});

export const SettingsContextProvider = (props: any) => {
  const { children } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [settings, setSettings] = useState<any>({
    from: dayjs().startOf("day").toDate(),
    to: dayjs().endOf("day").toDate(),
    headerHeight: "150px",
  });

  useEffect(() => {
    setSettings({
      ...settings,
      isDesktopOrLaptop: isDesktopOrLaptop,
      sidebarMenu: isDesktopOrLaptop,
    });
  }, [isDesktopOrLaptop]);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {children}
    </SettingsContext.Provider>
  );
};
