import useItems from "../../../shared/useItems";

const useCustomers = (props: any) => {
  const { data = null, accountId } = props;
  return useItems({
    url: `/${accountId}/customers/listByUser`,
  });
};

export default useCustomers;
