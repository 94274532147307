import { Box, Divider, Typography } from "@mui/material";

const Heading = (props: any) => {
  const { text } = props;

  return (
    <Typography variant="h5" color="text.primary">
      {text}
    </Typography>
  );
};

export default Heading;
