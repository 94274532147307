import usePostItems from "../../../shared/usePostItems";

const useAccounts = () => {
  return usePostItems({
    url: `accounts/list`,
    data: {},
  });
};

export default useAccounts;
