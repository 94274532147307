import usePostItems from "../../../shared/usePostItems";

const useJobs = (props: any) => {
  const { accountId } = props;
  return usePostItems({
    ...props,
    url: `bookings/${accountId}/driverlist`,
  });
};

export default useJobs;
