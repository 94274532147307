import { useEffect, useState } from "react";
import useApi from "../useApi";

const useItems = (props: any) => {
  const { url } = props;
  const { getApi } = useApi();
  const [items, setItems] = useState<any>([]);

  const refresh = async () => {
    const response = await getApi().get(url);
    if (response) setItems(response.data);

    return response;
  };

  useEffect(() => {
    refresh();
  }, []);

  return [items, refresh];
};

export default useItems;
