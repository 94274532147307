import Heading from "../../shared/Heading";

const UserSettings = () => {
  return (
    <>
      <Heading text="User Settings" />
    </>
  );
};

export default UserSettings;
