import { useContext, useState } from "react";
import { UserProfileContext } from "../../context/UserProfileContext";
import useCustomersByUser from "../customer/hooks/useCustomersByUser";
import MyAutocomplete from "./MyAutocomplete";

const MyCustomerAutocomplete = (props: any) => {
  const profile: any = useContext(UserProfileContext);
  const [customers, refresh] = useCustomersByUser({
    accountId: profile.accountId,
  });

  return <MyAutocomplete {...props} options={customers} />;
};

export default MyCustomerAutocomplete;
