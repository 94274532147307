import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useContext } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { UserProfileContext } from "../context/UserProfileContext";
import MemberMenu from "./MemberMenu";
import DriverMenu from "./DriverMenu";
import ManagerMenu from "./ManagerMenu";
import AdministratorMenu from "./AdministratorMenu";
import { Logout, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const drawerWidth = 240;

const SidebarMenu = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [settings, setSettings] = useContext<any>(SettingsContext);
  const profile: any = useContext(UserProfileContext);

  const toggleSidebarMenu = () => {
    if (settings.isDesktopOrLaptop) return;
    setSettings({ ...settings, sidebarMenu: !settings.sidebarMenu });
  };

  return (
    <>
      <Drawer
        variant={settings.isDesktopOrLaptop ? "permanent" : "temporary"}
        open={settings.sidebarMenu}
        onClose={toggleSidebarMenu}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {profile?.accountId === null && (
            <>
              <List>
                <ListItemButton onClick={() => navigate("user-settings")}>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => instance.logout()}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItemButton>
              </List>
              {profile?.isAdministrator && (
                <AdministratorMenu onClose={toggleSidebarMenu} />
              )}
            </>
          )}
          {profile?.accountId && (
            <>
              <MemberMenu onClose={toggleSidebarMenu} />
              {profile?.isDriver && <DriverMenu onClose={toggleSidebarMenu} />}
              {profile?.isManager && (
                <ManagerMenu onClose={toggleSidebarMenu} />
              )}
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default SidebarMenu;
