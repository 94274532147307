import usePostItems from "../../../shared/usePostItems";

const useBookings = (props: any) => {
  const { accountId, data, isDefered } = props;
  return usePostItems({
    url: `/bookings/${accountId}/userList`,
    data,
    isDefered,
  });
};

export default useBookings;
