import { useParams } from "react-router-dom";
import useItem from "../../../shared/useItem";

const useUserProfile = () => {
  const { accountId } = useParams();

  return useItem({ url: `users/profile/${accountId ?? ""}` });
};

export default useUserProfile;
