import { useEffect, useState } from "react";

const useConfig = () => {
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    const loadAsync = async () => {
      const response = await fetch("/config.json");

      if (response) {
        const newConfig = await response.json();
        setConfig(newConfig);
      }
    };

    loadAsync();
  }, []);

  return [config];
};

export default useConfig;
