import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import useUpdateUser from "./hooks/useUpdateUser";
import { Delete, Refresh, Save, Update } from "@mui/icons-material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import useUser from "./hooks/useUser";
import { useSnackbar } from "notistack";
import { UserProfileContext } from "../../context/UserProfileContext";
import MyTextField from "../forms/MyTextField";
import MyCheckbox from "../forms/MyCheckbox";
import { Formik } from "formik";

const ManagerUser = () => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const profile: any = useContext(UserProfileContext);
  const { update } = useUpdateUser({ accountId: profile.accountId });
  const [item, refresh] = useUser({
    accountId: profile.accountId,
    isDefered: id === undefined,
    id,
  });
  const methods = useForm();

  useEffect(() => {
    if (!item) return;
    methods.reset(item);
  }, [item]);

  const onRefresh = async () => {
    const response = await refresh(id);
    if (response) {
      enqueueSnackbar("Refreshed", { variant: "success" });
    }
  };

  const onUpdate = async (data: any) => {
    const newUser = {
      ...data,
      userId: profile.id,
      accountId: profile.id,
    };
    const response: any = await update(newUser);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id)
        navigate(`/${profile.accountId}/manager/user/${response.data.id}`);
    }
  };

  return (
    <Formik
      initialValues={item ?? {}}
      enableReinitialize={true}
      onSubmit={(values) => {
        onUpdate(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper elevation={4} sx={{ p: 1 }}>
            <Stack direction="row" p={2} spacing={1}>
              <Heading text={`Manager User #${id}`} />
              <Tooltip title="Save">
                <IconButton size="small" type="submit">
                  <Save />
                </IconButton>
              </Tooltip>
            </Stack>

            <Grid container>
              <Grid item xs={12} pb={1}>
                <MyTextField name="name" label="Name" fullWidth readOnly />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField name="email" label="Email" fullWidth readOnly />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyCheckbox name="isDriver" label="Driver" />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyCheckbox name="isManager" label="Manager" />
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default ManagerUser;
