import { Box } from "@mui/material";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";
import Main from "./Main";
import { useContext } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { UserProfileContextProvider } from "../context/UserProfileContext";

const AuthenticatedPage = () => {
  const [settings] = useContext<any>(SettingsContext);

  if (!settings) return <></>;

  return (
    <>
      <UserProfileContextProvider>
        <Box sx={{ display: "flex" }}>
          <Header />
          {settings.sidebarMenu && <SidebarMenu />}
          <Main />
        </Box>
      </UserProfileContextProvider>
    </>
  );
};

export default AuthenticatedPage;
