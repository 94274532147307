import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Add, Refresh } from "@mui/icons-material";
import Heading from "../../shared/Heading";
import useAccounts from "./hooks/useAccounts";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";

const AdministratorAccounts = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [data] = useState({ data: {} });
  const [accounts, refresh] = useAccounts();

  const columns = [
    { name: "id", label: "Id" },
    { name: "name", label: "Name" },
    { name: "address", label: "Address" },
    { name: "email", label: "Email" },
    { name: "website", label: "Website" },
    { name: "contact", label: "Contact" },
  ];

  const onRefresh = async () => {
    const response = await refresh();
    if (response) enqueueSnackbar("Refreshed", { variant: "success" });
  };

  const onRowClick = (row: any, { dataIndex }: any) => {
    const id = accounts[dataIndex].id;
    navigate(`/administrator/account/${id}`);
  };

  const onAdd = () => {
    navigate(`/administrator/account`);
  };

  return (
    <>
      <MUIDataTable
        title={
          <>
            <Stack direction="row" alignItems="start" spacing={1}>
              <Heading text="Accounts" />
              <Tooltip title="Refresh">
                <IconButton onClick={() => onRefresh()} size="small">
                  <Refresh />
                </IconButton>
              </Tooltip>
              <Tooltip title="New Account">
                <IconButton onClick={() => onAdd()} size="small">
                  <Add />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        }
        data={accounts}
        columns={columns}
        options={{ selectableRowsHideCheckboxes: true, onRowClick }}
      />
    </>
  );
};

export default AdministratorAccounts;
