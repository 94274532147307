import usePostItems from "../../../shared/usePostItems";

const useDrivers = (props: any) => {
  const { accountId, data } = props;
  return usePostItems({
    url: `users/drivers/${accountId}`,
    data,
  });
};

export default useDrivers;
