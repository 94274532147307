import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import MyAddress from "./MyAddress";
import { Add, Remove } from "@mui/icons-material";
import { useEffect } from "react";
import { FieldArray, useFormikContext } from "formik";

const MyVia = (props: any) => {
  const { name, label, onUpdate } = props;
  const { values } = useFormikContext<any>();
  const via = values[name];

  useEffect(() => {
    onUpdate?.(via);
  }, [via]);

  return (
    <Paper elevation={1} sx={{ p: 1, mt: 1, mb: 1 }}>
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Typography variant="caption">{label}</Typography>
              <IconButton
                size="small"
                onClick={() => push({ address: undefined })}
              >
                <Tooltip title="Add">
                  <Add />
                </Tooltip>
              </IconButton>
            </Stack>

            {via &&
              via.map((field: any, index: number) => (
                <Stack key={`via-${index}`} direction="row" padding={0.5}>
                  <div style={{ width: "100%" }}>
                    <MyAddress
                      name={`${name}.${index}.address`}
                      label=""
                      initialData={field.address}
                      rules={{ required: true }}
                    />
                  </div>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <Tooltip title="Remove">
                      <Remove />
                    </Tooltip>
                  </IconButton>
                </Stack>
              ))}
          </>
        )}
      />
    </Paper>
  );
};

export default MyVia;
