import { Grid, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import { Save } from "@mui/icons-material";
import useUser from "./hooks/useUser";
import useUpdateUser from "./hooks/useUpdateUser";
import { useSnackbar } from "notistack";
import useAccounts from "./hooks/useAccounts";
import { UserProfileContext } from "../../context/UserProfileContext";
import MyTextField from "../forms/MyTextField";
import MyCheckbox from "../forms/MyCheckbox";
import MyAutocomplete from "../forms/MyAutocomplete";
import { Formik } from "formik";

const AdministratorUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const profile: any = useContext(UserProfileContext);
  const [accounts] = useAccounts();
  const { update } = useUpdateUser();
  const [item, refresh] = useUser({
    isDefered: id === undefined,
    id,
  });

  const onUpdate = async (data: any) => {
    const newUser = {
      ...data,
      userId: profile.id,
      accountId: profile.id,
    };
    const response: any = await update(newUser);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id)
        navigate(`/${profile.accountId}/manager/user/${response.data.id}`);
    }
  };

  return (
    <Formik
      initialValues={item ?? {}}
      enableReinitialize={true}
      onSubmit={(values) => {
        onUpdate(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper elevation={4} sx={{ p: 1 }}>
            <Stack direction="row" p={2} spacing={1}>
              <Heading text={`User #${id}`} />
              <Tooltip title="Save">
                <IconButton size="small" type="submit">
                  <Save />
                </IconButton>
              </Tooltip>
            </Stack>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MyTextField name="name" label="Name" readOnly fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyTextField name="email" label="Email" readOnly fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MyCheckbox name="isAdministrator" label="Administrator" />
              </Grid>

              <Grid item xs={12}>
                <MyAutocomplete
                  name="managers"
                  label="Managers"
                  options={accounts}
                  multiple
                />
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default AdministratorUser;
