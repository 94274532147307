import { createContext } from "react";
import useConfig from "../useConfig";

export const ConfigContext = createContext({});

export const ConfigContextProvider = (props: any) => {
  const { children } = props;

  const [config] = useConfig();

  if (!config) return <></>;

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
