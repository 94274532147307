import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import AuthenticatedPage from "./layout/AuthenticatedPage";
import { SettingsContextProvider } from "./context/SettingsContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";

function App() {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect();
    }
  }, [inProgress, isAuthenticated, instance]);

  return (
    <>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsContextProvider>
            {!isAuthenticated && <>Authenticating...{inProgress}</>}
            {isAuthenticated && <AuthenticatedPage />}
          </SettingsContextProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
