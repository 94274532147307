import { Grid, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import useUpdateBooking from "./hooks/useUpdateBooking";
import useBooking from "./hooks/useBooking";
import { Add, Cancel, Check, Save, ThumbUp } from "@mui/icons-material";
import { useForm, FormProvider } from "react-hook-form";
import Status from "../../shared/Status";
import { useSnackbar } from "notistack";
import { UserProfileContext } from "../../context/UserProfileContext";
import GMRouteMap from "../../shared/GMRouteMap";
import MyAddress from "../forms/MyAddress";
import MyTextField from "../forms/MyTextField";
import MyDateTimePicker from "../forms/MyDateTimePicker";
import MyFlightNo from "../forms/MyFlightNo";
import MyVia from "../forms/MyVia";
import * as yup from "yup";
import { Formik } from "formik";
import MyCustomerAutocomplete from "../forms/MyCustomerAutocomplete";

const BookingSchema = yup.object().shape({
  customerId: yup.number().required("Required"),
  passengerName: yup.string().required("Required"),
  passengerPhone: yup.string().required("Required"),
  fromAddress: yup.string().required("Required"),
  toAddress: yup.string().required("Required"),
  fromTime: yup.string().required("Required"),
  toTime: yup.string().required("Required"),
});

const Job = () => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [item, refresh] = useBooking({
    isDefered: id === undefined,
    id,
  });
  const { update, del, get } = useUpdateBooking();
  const profile: any = useContext(UserProfileContext);
  const [route, setRoute] = useState<any>();

  useEffect(() => {
    if (!item) return;
    setRoute(item);
  }, [item]);

  const onUpdate = async (booking: any) => {
    const response: any = await update(booking);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id) navigate(`/${profile.accountId}/driver/job/${response.data.id}`);
    }
  };

  const onUpdateStatus = async (status: string) => {
    const response = await get(`${id}/status/${status}`);
    if (response) {
      enqueueSnackbar("Status updated", { variant: "success" });
      await refresh(id);
    }
  };

  if (!item) return <></>;

  return (
    <>
      <Formik
        initialValues={item}
        enableReinitialize={true}
        validationSchema={BookingSchema}
        onSubmit={(values) => {
          onUpdate(values);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Paper elevation={4} sx={{ p: 1, mb: 2 }}>
              <Stack direction="row" p={2} spacing={1}>
                <Heading text={`Job #${id}`} />
                {item && <Status label={item.status} />}
                {!id && (
                  <Tooltip title="Add">
                    <IconButton size="small" type="submit">
                      <Add />
                    </IconButton>
                  </Tooltip>
                )}
                {id && (
                  <>
                    <Tooltip title="Save">
                      <IconButton size="small" type="submit">
                        <Save />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <IconButton
                        size="small"
                        onClick={() => onUpdateStatus("Cancelled")}
                      >
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {id && profile.isManager && (
                  <>
                    <Tooltip title="Confirm">
                      <IconButton
                        size="small"
                        onClick={() => onUpdateStatus("Confirmed")}
                      >
                        <ThumbUp />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Complete">
                      <IconButton
                        size="small"
                        onClick={() => onUpdateStatus("Completed")}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MyCustomerAutocomplete name="customerId" label="Customer" />
                </Grid>
                <Grid item xs={12} pb={1}>
                  <MyTextField name="passengerName" label="Passenger Name" />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField name="passengerPhone" label="Passenger Phone" />
                </Grid>
                <Grid item xs={12}>
                  <MyAddress
                    name="fromAddress"
                    label="Pickup from"
                    onUpdate={(e: any) =>
                      setRoute({ ...route, fromAddress: e })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyFlightNo
                    name="fromFlightNo"
                    label="Arrival FlightNo"
                    arrival
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyDateTimePicker name="fromTime" label="Pickup Time" />
                </Grid>
                <Grid item xs={12}>
                  <MyVia
                    name="via"
                    label="Via"
                    onUpdate={(e: any) => setRoute({ ...route, via: e })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyAddress
                    name="toAddress"
                    label="Dropoff To"
                    onUpdate={(e: any) => setRoute({ ...route, toAddress: e })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyFlightNo name="toFlightNo" label="Departure Flight No" />
                </Grid>
                <Grid item xs={12}>
                  <MyDateTimePicker name="toTime" label="Dropoff To" />
                </Grid>

                <Grid item xs={12}>
                  <MyTextField name="notes" label="Notes" rows={5} />
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      </Formik>

      <GMRouteMap
        center={{ latitude: profile.latitude, longitude: profile.longitude }}
        route={route}
        showResult
      />
    </>
  );
};

export default Job;
