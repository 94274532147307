import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../shared/Heading";
import { Add, Delete, Save, Update } from "@mui/icons-material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import useCustomer from "./hooks/useCustomer";
import useUpdateCustomer from "./hooks/useUpdateCustomer";
import useAccountUsers from "./hooks/useAccountUsers";
import { UserProfileContext } from "../../context/UserProfileContext";
import MyTextField from "../forms/MyTextField";
import MyAutocomplete from "../forms/MyAutocomplete";
import { Formik } from "formik";

const Customer = () => {
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const profile: any = useContext(UserProfileContext);
  const [item, refresh] = useCustomer({
    accountId: profile.accountId,
    isDefered: id === undefined,
    id,
  });
  const { update, del, get } = useUpdateCustomer({
    accountId: profile.accountId,
  });
  const [users] = useAccountUsers({ accountId: profile.accountId });
  const methods = useForm();

  useEffect(() => {
    methods.reset({
      id,
      name: undefined,
    });
  }, [id]);

  useEffect(() => {
    if (!item) return;
    methods.reset(item);
  }, [item]);

  const onUpdate = async (customer: any) => {
    const data = { ...customer, accountId: profile.accountId };
    const response: any = await update(data);
    if (response) {
      enqueueSnackbar("Updated", { variant: "success" });
      if (!id)
        navigate(`/${profile.accountId}/manager/customer/${response.data.id}`);
    }
  };

  const onDelete = async () => {
    const response = await del(id);
    if (response) {
      enqueueSnackbar("Deleted", { variant: "success" });
      navigate(`/${profile.accountId}/manager/customers`);
    }
  };

  return (
    <Formik
      initialValues={item ?? {}}
      enableReinitialize={true}
      onSubmit={(values) => {
        onUpdate(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper elevation={4} sx={{ p: 1 }}>
            <Stack direction="row" p={2} spacing={1}>
              <Heading text={id ? `Customer #${id}` : "New Customer"} />

              <Tooltip title="Save">
                <IconButton size="small" type="submit">
                  <Save />
                </IconButton>
              </Tooltip>
              {id && (
                <>
                  <Tooltip title="Delete">
                    <IconButton size="small" onClick={onDelete}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>

            <Grid container spacing={1}>
              <Grid item xs={12} pb={1}>
                <MyTextField
                  name="name"
                  label="Name"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyTextField name="refNo" label="Ref No" />
              </Grid>
              <Grid item xs={12} pb={1}>
                <MyAutocomplete
                  name="users"
                  label="Users"
                  multiple
                  options={users}
                />
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
};

export default Customer;
