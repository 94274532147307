import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

const MyAddress = (props: any) => {
  const { name, label, onUpdate, initialData } = props;
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<any>();
  const [value, setValue] = useState("");

  useEffect(() => {
    onUpdate?.(value);
  }, [value]);

  const { ref } = usePlacesWidget({
    //apiKey: process.env.REACT_APP_GOOGLE,
    onPlaceSelected: (place: any) => {
      const newValue = place.formatted_address;
      setFieldValue(name, newValue);
      setValue(newValue);
    },
    options: {
      componentRestrictions: { country: ["au"] },
      types: ["geocode", "establishment"],
    },
  });

  return (
    <TextField
      key={name}
      inputRef={ref}
      name={name}
      label={label}
      InputLabelProps={{ shrink: true }}
      fullWidth
      size="small"
      variant="outlined"
      value={values[name] ?? initialData ?? ""}
      onChange={(e: any) => {
        const newValue = e.target.value;
        setFieldValue(name, newValue);
        setValue(newValue);
      }}
    />
  );
};

export default MyAddress;
