import { useContext } from "react";
import { createContext } from "react";
import useUserProfile from "../components/member/hooks/useUserProfile";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = (props: any) => {
  const { children } = props;

  const [profile] = useUserProfile();

  if (!profile) return <></>;

  return (
    <UserProfileContext.Provider value={profile}>
      {children}
    </UserProfileContext.Provider>
  );
};
