import { Favorite, Link } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const AccountDashboard = (props: any) => {
  const { item } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Card
        elevation={5}
        sx={{
          width: 345,
          cursor: "pointer",
          m: 1,
          ":hover": { backgroundColor: theme.palette.grey[50] },
        }}
        onClick={() => navigate(`/${item.account.id}`)}
      >
        <CardHeader
          avatar={<Avatar>{item.account.name[0]}</Avatar>}
          title={<Typography variant="h6">{item.account.name}</Typography>}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="button" color="text.primary">
                {item.account.contact}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.account.phone}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="text.secondary">
                {item.account.address}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.account.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.account.website}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
            {item.created > 0 && (
              <Badge
                badgeContent={item.created}
                color="secondary"
                sx={{ pl: 2 }}
              >
                <Chip label="Created" variant="outlined" />
              </Badge>
            )}
            {item.confirmed > 0 && (
              <Badge
                badgeContent={item.confirmed}
                color="secondary"
                sx={{ pl: 2 }}
              >
                <Chip label="Confirmed" color="primary" variant="outlined" />
              </Badge>
            )}
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default AccountDashboard;
