import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Refresh } from "@mui/icons-material";
import Heading from "../../shared/Heading";
import useUsers from "./hooks/useUsers";
import MUIDataTable from "mui-datatables";
import { UserProfileContext } from "../../context/UserProfileContext";
import { SettingsContext } from "../../context/SettingsContext";

const ManagerUsers = () => {
  const navigate = useNavigate();

  const [settings] = useContext(SettingsContext);
  const userProfile: any = useContext(UserProfileContext);
  const [users, refresh] = useUsers({
    accountId: userProfile.accountId,
    data: { userId: userProfile.id, accountId: userProfile.accountId },
  });

  const columns = [
    { name: "id", label: "Id" },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    {
      name: "isDriver",
      label: "Driver",
      options: {
        customBodyRender: (val: any) => <Checkbox checked={val} />,
      },
    },
    {
      name: "isManager",
      label: "Manager",
      options: {
        customBodyRender: (val: any) => <Checkbox checked={val} />,
      },
    },
  ];

  const onRowClick = (row: any, { dataIndex }: any) => {
    const id = users[dataIndex].id;
    navigate(`/${userProfile.accountId}/manager/user/${id}`);
  };

  return (
    <>
      <MUIDataTable
        title={
          <Stack direction="row" alignItems="start" spacing={1}>
            <Heading text="Manager Users" />
          </Stack>
        }
        data={users}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: true,
          onRowClick,
          pagination: false,
          filter: false,
          viewColumns: false,
          print: false,
          download: false,
          tableBodyMaxHeight: `calc(100vh - ${settings.headerHeight})`,
          customToolbar: () => (
            <>
              <Tooltip title="Refresh">
                <IconButton onClick={() => refresh()} size="small">
                  <Refresh />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
      />
    </>
  );
};

export default ManagerUsers;
