import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext } from "formik";

const MyAutocomplete = (props: any) => {
  const { name, label, rules, options, multiple = false } = props;
  const formik = useFormikContext<any>();

  const getOptions = (ids: any) => {
    if (multiple) {
      if (!ids) return [];
      if (!options) return [];
      return ids.map((id: any) => options.find((a: any) => a.id == id));
    } else {
      if (!ids) return undefined;
      return options.find((a: any) => a.id == ids);
    }
  };

  const onChange = (e: any, v: any): any => {
    if (multiple) {
      // list
      formik.setFieldValue(
        name,
        v.map((a: any) => a.id)
      );
    } else {
      formik.setFieldValue(name, v?.id);
    }
  };

  return (
    <Autocomplete
      id="tags-outlined"
      options={options}
      getOptionLabel={(option: any) => option?.name ?? ""}
      defaultValue={multiple ? [] : ""}
      value={getOptions(formik.values[name]) ?? ""}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={Boolean(formik.errors[name])}
          helperText={<>{formik.errors[name]}</>}
        />
      )}
      size="small"
      isOptionEqualToValue={(option: any, value: any) => {
        return value === "" || option?.id === value?.id;
      }}
      multiple={multiple}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
    />
  );
};

export default MyAutocomplete;
