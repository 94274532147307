import { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Divider,
  ListItemIcon,
} from "@mui/material";
import {
  Home,
  Logout,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { SettingsContext } from "../context/SettingsContext";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../context/UserProfileContext";

const Header = () => {
  const [settings, setSettings] = useContext<any>(SettingsContext);
  const userProfile: any = useContext(UserProfileContext);
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState<any>();

  const logout = async () => {
    handleCloseUserMenu();
    instance.logout();
  };

  const handleMenu = (e: any) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleSidebarMenu = () => {
    setSettings({
      ...settings,
      sidebarMenu: !settings.sidebarMenu,
    });
  };

  const onNavigate = (url: string) => {
    handleCloseUserMenu();
    navigate(url);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleSidebarMenu}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => onNavigate("/")}
        >
          <Home />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        {userProfile && (
          <>
            <Tooltip title="User">
              <Button size="large" onClick={handleMenu}>
                <Avatar>{userProfile.name[0]}</Avatar>
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Typography>{userProfile?.name}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => onNavigate("user-settings")}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <Typography>Settings</Typography>
              </MenuItem>
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <Typography>Logout</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
