import usePostItems from "../../../shared/usePostItems";

const useCustomers = (props: any) => {
  const { data = null, accountId } = props;
  return usePostItems({
    url: `/${accountId}/customers/list`,
    data,
  });
};

export default useCustomers;
