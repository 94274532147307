import { Chip } from "@mui/material";

const statusColors: any = {
  Created: "default",
  Confirmed: "primary",
  Completed: "success",
  Cancelled: "warning",
};

const Status = (props: any) => {
  const { label } = props;

  return (
    <>
      <Chip
        label={label}
        //variant="outlined"
        sx={{ ml: 1, mr: 1 }}
        color={statusColors[label]}
      />
    </>
  );
};

export default Status;
