import { Box, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";

const Main = () => {
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Main;
